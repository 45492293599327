.jobApplyCard_Container {
  width: 340px;
  border: 1px solid #e1e2e3;
  background-color: #fff;
  padding: 24px 20px;
  border-radius: 3px;
  height: auto;
  box-sizing: border-box;
  &__userContainer {
    h3 {
      border-left: 2px solid #258bf7;
      padding-left: 20px;
      margin: 0 -20px;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
    &__username {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e1e2e3;
      margin-bottom: 5px;
      h4 {
        line-height: 50px;
        font-size: 16px;
        font-weight: 600;
        width: 80px;
        color: #333;
        float: left;
      }
      &__name {
        width: calc(100%);
        height: 50px;
        padding: 0;
        border: none;
        border-bottom: 1px solid #e1e2e3;
        line-height: 50px;
        font-size: 16px;
        font-weight: 600;
      }
    }
    &__email {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e1e2e3;
      margin-bottom: 5px;
      h4 {
        line-height: 50px;
        font-size: 16px;
        font-weight: 600;
        width: 80px;
        color: #333;
        float: left;
      }
      &__account {
        width: calc(100%);
        height: 50px;
        padding: 0;
        border: none;
        border-bottom: 1px solid #e1e2e3;
        line-height: 50px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  &__fileContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: flex-start;
    order: 0;
    flex: 1 1;
    align-self: auto;
    margin-top: 20px;
    &__button {
      width: 100%;
      height: 50px;
      border-radius: 25px;
      background-color: #507af8;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: normal;
      color: #fff;
      text-align: center;
      cursor: pointer;
      margin-top: 20px;
    }
    &__desc {
      margin-top: 20px;
    }
  }
}

@media (max-width: 767px) {
  .jobApplyCard_Container {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .jobApplyCard_Container {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .jobApplyCard_Container {
    height: auto;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .jobApplyCard_Container {
    width: 340px;
    border: 1px solid #e1e2e3;
    background-color: #fff;
    padding: 24px 20px;
    border-radius: 3px;
    height: auto;
    box-sizing: border-box;
  }
}
