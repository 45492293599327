/// 메인 페이지
.mainpage {
  width: 100vw;
  padding-top: 50px;
}
/// 헤더
.header {
  position: fixed;
  width: 100vw;
  height: 50px;
  top: 0;
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 10%);
  z-index: 800;
  .header-content {
    margin: 0 auto;
    height: 50px;
    width: 80%;
    position: relative;
    .nav-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      .right-content {
        display: flex;
        .logo {
          width: 17px;
          height: 14px;
          object-fit: contain;
          margin-top: 19px;
          margin-right: 13px;
          cursor: pointer;
        }
        .wanted {
          font-size: 22px;
          font-weight: bold;
          cursor: pointer;
          padding: 14px;
          text-decoration: none;
          a {
            text-decoration: none;
            color: black;
          }
        }
      }
      .center-content {
        display: flex;
        justify-content: space-evenly;
        height: inherit;
        text-align: center;
        margin: 0;
        list-style: none;
        .navbar-item {
          height: inherit;
          margin-right: 10px;
          :hover {
            box-shadow: inset 0 -2px #258bf7;
          }
          a {
            text-decoration: none;
            position: relative;
            vertical-align: middle;
            font-size: 14px;
            line-height: 20px;
            padding: 15px;
            display: inline-block;
            color: black;
          }
        }
      }
      .left-content {
        height: 100%;
        .left-tag {
          list-style: none;
          display: flex;
          height: 100%;
          .left-tags {
            position: relative;
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-top: 8px;
            .magnifier {
              margin-top: 2px;
              width: 22px;
              cursor: pointer;
            }
            .sign-in {
              cursor: pointer;
            }
            a {
              text-decoration: none;
              color: black;
              line-height: 30px;
              padding: 0 10px;
              font-size: 14px;
              font-weight: bold;
            }
          }
          .left-btn {
            position: relative;
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-top: 3.5px;
            a {
              text-decoration: none;
              color: black;
              line-height: 30px;
              padding: 0 10px;
              font-size: 14px;
              font-weight: bold;
              .anchor {
                border-radius: 15px;
                font-size: 14px;
                color: #666;
                line-height: 30px;
                padding: 0 10px;
                font-weight: 400;
                border: 1px solid #e1e2e3;
                cursor: pointer;
              }
              .left-content {
                height: 100%;
                .left-tag {
                  list-style: none;
                  display: flex;
                  height: 100%;
                  .left-tags {
                    position: relative;
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                    margin-top: 10px;
                    .magnifier {
                      width: 22px;
                      cursor: pointer;
                    }
                    .left-btn {
                      position: relative;
                      display: inline-block;
                      height: 100%;
                      vertical-align: middle;
                      margin-top: 3.5px;
                      a {
                        text-decoration: none;
                        color: black;
                        line-height: 30px;
                        padding: 0 10px;
                        font-size: 14px;
                        font-weight: bold;
                        .anchor {
                          border-radius: 15px;
                          font-size: 14px;
                          color: #666;
                          line-height: 30px;
                          padding: 0 10px;
                          font-weight: 400;
                          border: 1px solid #e1e2e3;
                          cursor: pointer;
                        }
                      }
                    }
                  }
                }
                .left-btn {
                  position: relative;
                  display: inline-block;
                  height: 100%;
                  vertical-align: middle;
                  margin-top: 3.5px;
                  a {
                    text-decoration: none;
                    color: black;
                    line-height: 30px;
                    padding: 0 10px;
                    font-size: 14px;
                    font-weight: bold;
                    .anchor {
                      border-radius: 15px;
                      font-size: 14px;
                      color: #666;
                      line-height: 30px;
                      padding: 0 10px;
                      font-weight: 400;
                      border: 1px solid #e1e2e3;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.sign-in {
  margin-top: 5px;
  margin-left: 5px;
}

/// 중간 버튼
.bigbtn-link {
  width: 55%;
  text-decoration: none;
}
.bigbtn {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 38.5px;
  background-image: linear-gradient(
    90deg,
    #01a9fe -12%,
    #2f5fef 37%,
    #046afe 72%,
    #00d2dd 110%
  );
  padding: 21px 30px;
  font-size: 18px;
  line-height: normal;
  width: 55%;
  margin: 0 auto;
  margin-bottom: 70px;
  .magnifi {
    width: 30px;
    margin-right: 10px;
  }
}
.recommend {
  width: 80%;
  margin: 0 auto;
}
/// 아래 지점
.footer__container {
  width: 100%;
  padding: 18px 0 30px;
  background-color: #fff;
  border-top: 1px solid #ececec;
  &__top {
    width: 90%;
    max-width: 1060px;
    margin: 0 auto 18px;
    display: flex;
    box-sizing: border-box;
    &__menu {
      flex-grow: 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      &__logo {
        margin-right: 50px;
        img {
          width: 110px;
          height: 39px;
          vertical-align: middle;
        }
      }
      &__text {
        display: flex;
        a {
          font-size: 15px;
          color: #3a3a3a;
          font-weight: 500;
          line-height: 2.6;
          margin-right: 45px;
          white-space: nowrap;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
    &__sns {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      line-height: 2.6;
      a {
        position: relative;
        display: block;
        float: left;
        height: 20px;
        img {
          margin-left: 14px;
          vertical-align: middle;
        }
      }
    }
  }
  &__bottom {
    width: 90%;
    max-width: 1060px;
    margin: 0 auto 18px;
    display: flex;
    border-top: 1px solid #ececec;
    padding-top: 25px;
    &__pTag {
      float: left;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.6666666667em;
      color: #767676;
      width: calc(100% - 290px);
      margin-right: 40px;
    }
  }
}
///공고 등록
.companypage {
  width: 100vw;
  .company-write {
    padding: 130px;
  }
}
@media (max-width: 767px) {
  .header {
    .header-content {
      height: 110px;
      .nav-bar {
        .right-content {
        }
        .center-content {
        }
        .left-content {
        }
      }
    }
  }
  .footer__container {
    // padding: 30px 25px 50px;
    &__top {
      width: 100%;
      flex-direction: column;
      &__menu {
        flex-direction: column;
        &__logo {
          margin-right: 0;
        }
        &__text {
          margin-top: 16px;
          a {
            font-size: 14px;
            margin: 0 9px;
          }
        }
      }
      &__sns {
        text-align: center;
        margin: 0 auto 10px;
      }
    }
    &__bottom {
      border: none;
      flex-direction: column;
      &__pTag {
        color: #818181;
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        line-height: 1.5;
      }
    }
  }
}

@media (min-width: 1200px) {
  .header {
    .header-content {
      width: 90%;
      max-width: 1060px;
      margin: 0 auto;
      height: 50px;
    }
  }
}
