.companyDetailCard__Container {
  margin: 40px 0 30px;
  display: block;
  h2 {
    color: #333;
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 10px;
    word-break: keep-all;
    word-wrap: break-word;
  }
  &__companyInfo h6 {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  &__companyInfo span {
    position: relative;
    white-space: nowrap;
    color: #999;
    margin-left: 10px;
  }
  &__company {
    p {
      color: #333;
      font-weight: 400;
      word-wrap: break-word;
      white-space: pre-wrap;
      font-size: 16px;
      line-height: 1.75;
    }
    h6 {
      margin: 20px 0 0;
      font-weight: 600;
      color: #333;
      font-size: 16px;
      line-height: 1.75;
    }
  }
  &__stackList {
    display: inline-flex;
    flex-wrap: wrap;
    grid-gap: 10px 8px;
    gap: 10px 8px;
    margin-top: 5px;
    margin-right: 5px;
    &__skill {
      background-color: #e4f4ec;
      border-radius: 20px;
      padding: 9px 14px;
      font-size: 12px;
      line-height: 14.4px;
      font-weight: 600;
    }
  }
}

@media (max-width: 767px) {
  .companyDetailCard__Container {
    &__location {
    }
    &__company {
      p {
      }
      h6 {
      }
      &__stackList {
        grid-gap: 8px;
        gap: 8px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .companyDetailCard__Container {
    &__location {
    }
    &__company {
      margin-bottom: 60px;
      padding-right: 20px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .companyDetailCard__Container {
    &__location {
    }
    &__company {
      margin-bottom: 60px;
      padding-right: 20px;
    }
  }
}
.JobDescription_JobDescription__VWfcb {
  margin-bottom: 60px;
  padding-right: 20px;
}
