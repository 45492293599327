@import url('https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner:wght@600&family=Noto+Sans+KR:wght@300;400;500;700&family=Pacifico&family=Poppins:wght@200;300;400;500&family=Roboto:wght@300;400;500;700;900&display=swap');

/*  Light 300  */
/* Regular 400 */
/* Medium 500  */
/* Bold 700 */
body {
  font-family: 'Edu VIC WA NT Beginner', cursive;
  font-family: 'Noto Sans KR', sans-serif;
  font-family: 'Pacifico', cursive;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  
}
::-webkit-scrollbar {
  /* display: none; */
}