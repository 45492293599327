.slider {
  padding: 30px 60px 0 60px;
  margin-bottom: 70px;
  position: relative;
  top: 20px;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  height: 300px;
  text-align: center;
  font-size: 18px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination-bullets {
  display: none;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev,
.swiper-button-next {
  color: white;
}
