.search{
    left: 0;
    top: 0;
    width: 100%;
    position: fixed;
    background: #fff;
    z-index: 801;

    .search-container{        
        width: 100%;
        height: 50px;
        margin: 0 auto;
        padding: 30px 0 140px;
        position: relative;
        background-color: #fff;
        max-width: 1060px;
        input{
            margin: 0 auto;
            height: 50px;
            line-height: 50px;
            width: 100%;
            padding: 0 36px 0 60px;
            border: 1px solid #f2f4f7;
            border-radius: 25px;
            font-size: 16px;
            font-weight: 400;
            color: #767676;
            background-color: #f2f4f7;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            
        }
        input:focus{
            outline: 1px solid blue;  
        }
        img{
            overflow: hidden;
            position: absolute;
            top: 55px;
            left: 20px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            color: #939393;
            width: 18px;
            height: 18px;
            margin: 0 16px 0 6px;
        }
        button{
            display: none;
            position: absolute;
            font-size: 12px;
            right: 0;
            top: 0;
            width: 50px;
            height: 50px;
            color: #999;
            padding: 15px;
        }
        
    }
}
.black-out{
    content: "";
    display: block;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    z-index: -1;
}