.cards{
    // $len:4;
    // $iconWidth: 250px;
    // $Containwidth: calc(80% + (80% - #{$len} * #{$iconWidth}) / (#{$len}-1));
    // width: $Containwidth;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    // position: relative;
    // left: 50%;
    // transform: translate(-50%,0);
    justify-content: center;
    padding-right: 100px;
    padding-left: 100px;
}
.card-item{
    text-decoration: none;
    color: black;
    border-radius: 4px;
    margin-bottom: 60px;
    // width: 25%;
    .card-item-list{
        width: 250px;
        margin: 0 auto;
    }
    img{
        border-radius: 4px;
        width: 249px;
        height: 200px;
        margin-bottom: 8px
    }
    .card-title{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 7px;
    }
    .card-company{
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 5px;
    }
    .company-region{
        color: #999;
        line-height: normal;
        font-size: 14px;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        // overflow: hidden;
    }
    
    .company-pay{
        margin-top: 10px;
        color: #333;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        word-break: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        // overflow: hidden;
    }    
}
.scrolldown-nope{
    display: none;
}
