.recommend-comment{
    width: 100%;
    margin-bottom: 14px;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.05;
    letter-spacing: normal;
    color: #111;
    .logowant{
        width: 115px;
        margin-right: 4px;
    }
}

.position-ent{
    display: flex;
    text-decoration: none;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 400;
    color: #767676
}
.recommend-items{
    // display: flex;
    // justify-content: center;
    // align-items: center;
}
.reco-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.arrow-left{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: #fff;
    font-size: 16px;
    color: #767676;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
    border: 1px solid #e1e2e3;
    border-radius: 50%;
    pointer-events: all;
    cursor: pointer;
    
}
.arrow-right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: #fff;
    font-size: 16px;
    color: #767676;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
    border: 1px solid #e1e2e3;
    border-radius: 50%;
    pointer-events: all;
    cursor: pointer;
    margin-bottom: 10px;
}

// 카드들 넘기기
.swiper {
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev{
    color: #767676;
    // margin-right: 40px;
}
.swiper-button-next{
    color: #767676;
}
