.imageSwiperContainer {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.image img {
  width: 100%;
  height: 450px;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
  outline: 1px solid rgba(0, 0, 0, 0.05);
  outline-offset: -1px;
}
