///공고 등록
.companypage {
  width: 100vw;
  .company-write {
    display: flex;
    flex-direction: column;
    padding: 100px;
    input {
      width: 200px;
      margin: 20px auto;
      height: 21px;
      border-radius: 6px;
    }
    label {
      margin-top: 10px;
      margin-right: 10px;
      width: 200px;
      margin: 0 auto;
    }
    .company-stack {
      display: flex;
      border: 1px solid black;
      height: 20px;
      text-align: center;
      cursor: pointer;
      margin: 10px auto;
      width: 200px;
      height: 21px;
      border-radius: 6px;
    }
    .company-stacks {
      text-align: center;
      margin-bottom: 10px;
      height: 20px;
      width: 200px;
      background-color: gray;
      border: 1px solid white;
      cursor: pointer;
      margin: 0 auto;
    }
  }
  button {
    display: block;
    width: 100px;
    margin: 0 auto;
    background-color: #258bf7;
    height: 35px;
    border-radius: 20px;
    color: white;
    border: 1px white solid;
  }
}
.company-btn {
  background-color: #258bf7;
  height: 30px;
  border: none;
  border-radius: 12px;
  width: 120px;
  text-align: center;
  padding-top: 12px;
  margin: 0 auto;
  margin-bottom: 15px;
  color: white;
}
