@media (min-width: 1200px) {
  .jobPage_Container {
    max-width: 1060px;
    height: 100vh;
    margin: 0 auto;
    padding: 20px 0 80px;
    // background-color: blue;
    position: relative;
    margin: 50px auto;
    padding: 20px 0 80px;
    .jobDetailLeft {
      display: inline-block;
      width: calc(100% - 360px);
      vertical-align: top;
    }
    .jobDetailRight {
      top: 70px;
      position: fixed;
      right: calc((100% - 1060px) / 2);
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .jobPage_Container {
    background: #fff;
    margin-top: 70px;
    margin: 0 auto;
    padding: 20px 0 80px;
    width: 90%;
  }
  .jobDetailRight {
    // position: fixed;
    bottom: 0;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .jobPage_Container {
    margin: 72px auto;
    padding: 20px 0 0px;
    width: 90%;
  }
  .jobDetailRight {
    // position: fixed;
    bottom: 0;
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .jobPage_Container {
    margin: 0 auto;
    padding: 20px 0 80px;
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .jobPage_Container {
    width: 87.72%;
    margin: 0 auto;
    top: 50px;
  }
}
